import { map, filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
// utils
import { LoadManager } from "../../@types/load";
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc,
  addDoc,
  setDoc,
} from "firebase/firestore";
import { findCarrierById } from "./carrier";
// ----------------------------------------------------------------------
import { initializeApp } from "firebase/app";
import { fbConfig } from "config";
const app = initializeApp(fbConfig);
const db = getFirestore(app);
type LoadState = {
  isLoading: boolean;
  error: boolean;
  loadList: LoadManager[];
};

const initialState: LoadState = {
  isLoading: false,
  error: false,
  loadList: [],
};

const slice = createSlice({
  name: "load",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // ADD LOAD
    addLoadSuccess(state, action) {
      state.isLoading = false;
      state.loadList = [...state.loadList, action.payload];
    },
    // DELETE LOAD
    deleteLoad(state, action) {
      const deleteLoad = filter(
        state.loadList,
        (load) => load.id !== action.payload
      );
      state.loadList = deleteLoad;
    },
    // GET MANAGE LOAD
    getLoadListSuccess(state, action) {
      state.isLoading = false;
      state.loadList = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteLoad } = slice.actions;

// ------------------------Get Load List--------------------------------
export function getLoadList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const loads = await getDocs(collection(getFirestore(), "loads"));
      let findDocs = loads.docs.map((load) => {
        let data = load.data();
        data.id = load.id;
        return data;
      });
      dispatch(slice.actions.getLoadListSuccess(findDocs));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getLoads() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const loads = await getDocs(collection(getFirestore(), "loads"));
      let findDocs = loads.docs.map((load) => {
        let data = load.data();
        data.id = load.id;
        return data;
      });
      dispatch(slice.actions.getLoadListSuccess(findDocs));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export async function getCount() {
  const docs = await getDocs(collection(getFirestore(), "loads"));
  let count = 0;
  docs.docs.map((shipper) => {
    count++;
  });

  return count + 1;
}
export function addLoad(values: any, stopData: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const loadId = await getCount();
    const data = {
      loadNumber: loadId,
      customerId: values.customerId,
      contactRef: values.contactRef,
      customerLoad: values.customerLoad,
      carrierId: values.carrierId,
      status: values.status,
      rateDetails: {
        rate: values.rateDetails.rate,
        type: values.rateDetails.type,
      },
      salesPersonId: values.salesPersonId,
      freightDetails: {
        freightType: values.freightDetails.freightType,
        temperature: values.freightDetails.temperature,
        temperatureType: values.freightDetails.temperatureType,
        cycle: values.freightDetails.cycle,
      },
      requirements: {
        trailerType: values.requirements.trailerType,
        trailerSize: values.requirements.trailerSize,
        team: values.requirements.team,
      },
      stops: stopData,
      dispatches: values.dispatches,
      createdAt: Date.now(),
    };
    const load = await addDoc(collection(db, "loads"), data);
    dispatch(slice.actions.addLoadSuccess({ ...data, id: load.id }));
  };
}
export function updateLoad(values: any, stopData: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const data = {
      loadNumber: values.loadNumber,
      customerId: values.customerId,
      contactRef: values.contactRef,
      customerLoad: values.customerLoad,
      carrierId: values.carrierId,
      status: values.status,
      rateDetails: {
        rate: values.rateDetails.rate,
        type: values.rateDetails.type,
      },
      salesPersonId: values.salesPersonId,
      freightDetails: {
        freightType: values.freightDetails.freightType,
        temperature: values.freightDetails.temperature,
        temperatureType: values.freightDetails.temperatureType,
        cycle: values.freightDetails.cycle,
      },
      requirements: {
        trailerType: values.requirements.trailerType,
        trailerSize: values.requirements.trailerSize,
        team: values.requirements.team,
      },
      stops: stopData,
      dispatches: values.dispatches,
      createdAt: Date.now(),
    };
    await setDoc(doc(db, "loads", values.id), data);
    await getLoadList()();
  };
}
export function getDispatches() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const loads = await getDocs(collection(getFirestore(), "dispatches"));
      let findDocs = loads.docs.map((load) => {
        let data = load.data();
        data.id = load.id;
        return data;
      });
      dispatch(slice.actions.getLoadListSuccess(findDocs));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export async function findLoadById(id: string) {
  const findLoadDetails = await getDoc(doc(db, "loads", id));
  if (findLoadDetails.exists()) {
    return findLoadDetails.data();
  }
}

export async function findDispatchesByLoadId(id: string) {
  const findDispatchDetails = await getDoc(doc(db, "dispatches", id));
  if (findDispatchDetails.exists()) {
    return findDispatchDetails.data();
  }
}
