import { map, filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
// utils
import { DispatchManager } from "../../@types/dispatch";
import { getFirestore, collection, getDocs, getDoc, doc, deleteDoc } from "firebase/firestore";
import { findCarrierById } from "./carrier";
// ----------------------------------------------------------------------
import { initializeApp } from "firebase/app";
import { fbConfig } from "config";
const app = initializeApp(fbConfig)
const db = getFirestore(app)
type DispatchState = {
    isLoading: boolean;
    error: boolean;
    dispatchList: DispatchManager[];
};

const initialState: DispatchState = {
    isLoading: false,
    error: false,
    dispatchList: [],
};

const slice = createSlice({
    name: "dispatches",
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // DELETE LOAD
        deleteDispatch(state, action) {
            const deleteDispatch = filter(
                state.dispatchList,
                (dispatches) => dispatches.id !== action.payload
            );
            state.dispatchList = deleteDispatch;
        },
        // GET MANAGE LOAD
        getDispatchListSuccess(state, action) {
            state.isLoading = false;
            state.dispatchList = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteDispatch } = slice.actions;

// ------------------------Get Load List--------------------------------
export async function getDispatchList() {
    try {
        let colRef = collection(db, "dispatches")
        const dispatches = await getDocs(colRef);
        let findDocs = dispatches.docs.map((load) => {
            let data = load.data();
            data.id = load.id;
            return data
        });
        // let data = [...findDocs];
        // let startfilterData: any = []; 
        // let getFilterData: any = [];
        // data.forEach((record) => {
        //     startfilterData = [];
        //     for (let i = 1; i < record.stops.length; i++) {
        //         startfilterData.push([record.stops[i - 1], record.stops[i]])
        //     }
        //     record.stops = startfilterData;
        //     startfilterData.forEach((resp: any) => {
        //         record.stops = resp;
        //         getFilterData.push(record);
        //     })
        //     findDocs = getFilterData;   
        // })

        dispatch(
            slice.actions.getDispatchListSuccess(findDocs)
        );
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
    // };
}

export function getDispatches() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const loads = await getDocs(collection(getFirestore(), "dispatches"));
            let findDocs = loads.docs.map((load) => {
                let data = load.data();
                data.id = load.id;
                return data
            });
            dispatch(
                slice.actions.getDispatchListSuccess(findDocs)
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export async function findDispatchesByLoadId(id: string) {
    const findDispatchDetails = await getDoc(doc(db, 'dispatches', id))
    if (findDispatchDetails.exists()) {
        return findDispatchDetails.data();
    }
};

