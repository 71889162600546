import { map, filter } from "lodash"
import { createSlice } from "@reduxjs/toolkit"
import { dispatch } from "../store"
// utils
import { ShipperManager } from "../../@types/shipper"
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc
} from "firebase/firestore"
import { findCarrierById } from "./carrier"
// ----------------------------------------------------------------------
import { initializeApp } from "firebase/app";
import { fbConfig } from "config";
const app = initializeApp(fbConfig)
const db = getFirestore(app)
type ShipperState = {
  isLoading: boolean
  error: boolean
  shipperList: ShipperManager[]
}

const initialState: ShipperState = {
  isLoading: false,
  error: false,
  shipperList: []
}

const slice = createSlice({
  name: "shipper",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // DELETE shipper
    deleteShipper(state, action) {
      const deleteShipper = filter(
        state.shipperList,
        (shipper) => shipper.id !== action.payload
      )
      state.shipperList = deleteShipper
    },
    // GET MANAGE shipper
    getShipperListSuccess(state, action) {
      state.isLoading = false
      state.shipperList = action.payload
    }
  }
})

// Reducer
export default slice.reducer

// Actions
export const { deleteShipper } = slice.actions

// ------------------------Get shipper List--------------------------------
export function getShipperList() {
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      const shippers = await getDocs(collection(getFirestore(), "shippers"))
      let findDocs = shippers.docs.map((shipper) => {
        let data = shipper.data()
        data.id = shipper.id
        return data
      })
      dispatch(slice.actions.getShipperListSuccess(findDocs))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}
export async function findShipperById(id: string) {
  const findShipperDetails = await getDoc(doc(db, 'shippers', id))
  if (findShipperDetails.exists()) {
    return findShipperDetails.data();
  }
};
